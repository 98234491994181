<template>
  <div data-app>
    <div
      class="login-wrap"
      :style="{ 'background-image': 'url(' + require('@/assets/bg.jpg') + ')' }"
    >
      <div class="login-html">
        <input
          id="tab-1"
          type="radio"
          name="tab"
          class="sign-in"
          checked
        /><label for="tab-1" class="tab">Login</label>
        <input id="tab-2" type="radio" name="tab" class="sign-up" /><label
          for="tab-2"
          class="tab"
        ></label>
        <div class="login-form">
          <div class="sign-in-htm">
            <div class="group">
              <label for="user" class="label">Email</label>
              <input id="user" v-model="form.email" type="text" class="input" />
            </div>
            <div class="group">
              <label for="pass" class="label">Senha</label>
              <input
                v-model="form.password"
                id="pass"
                type="password"
                class="input"
                data-type="password"
                @keyup.enter="login()"
              />
            </div>
            <div class="group">
              <input id="check" type="checkbox" class="check" checked />
              <label for="check"
                ><span class="icon"></span> Manter conectado</label
              >
            </div>
            <div class="group">
              <button
                type="button"
                :disabled="btnLoadingSave"
                @click="login()"
                class="button"
              >
                Entrar
                <v-progress-circular
                  :width="1"
                  :size="20"
                  v-show="btnLoadingSave"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </button>
              <!-- <input
                type="button" :disabled="btnLoadingSave"
                @click="login()"
                class="button"
                value="Entrar"
              /> -->
            </div>
            <div class="hr"></div>
            <div class="foot-lnk">
              <input id="tab-2" type="radio" name="tab" class="sign-up" /><label
                for="tab-2"
                style="font-size: 14"
                class=""
                >Recuperar senha</label
              >
              <!-- <a href="#forgot">Recuperar senha</a> -->
            </div>
          </div>

          <div class="sign-up-htm">
            <div class="group">
              <label for="pass" class="label">Email</label>
              <input
                v-model="form_reset_password.email"
                type="text"
                class="input"
              />
            </div>
            <div class="group">
              <button
                type="button"
                class="button"
                @click="sendLinkResetPassword()"
                :disabled="btn_disabled"
              >
                Recuperar senha
                <v-progress-circular
                  :width="1"
                  :size="20"
                  v-show="loading_rec"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </button>
            </div>
            <div class="hr"></div>
            <div class="foot-lnk">
              <label for="tab-1">Login</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="dialogResponse.dialog" max-width="550px">
      <v-card>
        <v-card-title class="text-h5">{{ dialogResponse.msg }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" @click="dialogResponse.dialog = false">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "@/axios";
import { mapMutations } from "vuex";
import service from "./service";
export default {
  data() {
    return {
      btnLoadingSave: false,
      dialogResponse: {
        dialog: false,
        msg: "",
      },
      form: {
        email: "",
        password: "",
      },
      form_reset_password: {
        email: "",
      },
      loading_rec: false,
      btn_disabled: false,
    };
  },
  methods: {
    ...mapMutations("Login", ["setUser", "setConstruction","setPhoto"]),

    sendLinkResetPassword() {
      this.loading_rec = true;
      this.btn_disabled = true;
      service
        .sendLinkResetPassword(this.form_reset_password)
        .then((response) => {
          this.btn_disabled = false;
          this.loading_rec = false;
          this.dialogResponse.msg = response.data;
          this.dialogResponse.dialog = true;
        })
        .catch((response) => {
          this.loading_rec = false;
          this.btn_disabled = false;
          let errors = response.response.data;
          for (var erro in errors) {
            this.dialogResponse.msg = errors[erro][0];
            this.dialogResponse.dialog = true;
            break;
          }
        });
    },

    login() {
      this.btnLoadingSave = true;
      localStorage.removeItem("token");
      axios
        .post("auth/login", this.form)
        .then((response) => {
          this.btnLoadingSave = false;
          localStorage.setItem("token", response.data.access_token)
          this.setUser(response.data.user)
          this.setConstruction(response.data.construction);
          this.setPhoto(response.data.photo);
          localStorage.setItem("constructionid", response.data.construction.id)
          this.$router.push("/system/dashboard")
        })
        .catch(() => {
          this.btnLoadingSave = false;
        });
    },
  },
};
</script>

<style >
body {
  margin: 0;
  color: #6a6f8c;
  background: #c8c8c8;
  font: 600 16px/18px "Open Sans", sans-serif;
}
*,
:after,
:before {
  box-sizing: border-box;
}
.clearfix:after,
.clearfix:before {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
  display: block;
}
a {
  color: inherit;
  text-decoration: none;
}

.login-wrap {
  width: 100%;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 525px;
  min-height: 670px;
  position: relative;
  /* background: url(src/assets/bg.jpg) */
  /* no-repeat center; */
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.login-html {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 90px 70px 50px 70px;
  background: rgba(40, 57, 101, 0.9);
}
.login-html .sign-in-htm,
.login-html .sign-up-htm {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  transition: all 0.4s linear;
}
.login-html .sign-in,
.login-html .sign-up,
.login-form .group .check {
  display: none;
}
.login-html .tab,
.login-form .group .label,
.login-form .group .button {
  text-transform: uppercase;
}
.login-html .tab {
  font-size: 22px;
  margin-right: 15px;
  padding-bottom: 5px;
  margin: 0 15px 10px 0;
  display: inline-block;
  border-bottom: 2px solid transparent;
}
.login-html .sign-in:checked + .tab,
.login-html .sign-up:checked + .tab {
  color: #fff;
  border-color: #1161ee;
}
.login-form {
  min-height: 345px;
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
}
.login-form .group {
  margin-bottom: 15px;
}
.login-form .group .label,
.login-form .group .input,
.login-form .group .button {
  width: 100%;
  color: #fff;
  display: block;
}
.login-form .group .input,
.login-form .group .button {
  border: none;
  padding: 15px 20px;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.1);
}

.login-form .group input[data-type="password"] {
  text-security: circle;
  -webkit-text-security: circle;
}

.login-form .group .label {
  color: #aaa;
  font-size: 12px;
}
.login-form .group .button {
  background: #1161ee;
}
.login-form .group label .icon {
  width: 15px;
  height: 15px;
  border-radius: 2px;
  position: relative;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
}
.login-form .group label .icon:before,
.login-form .group label .icon:after {
  content: "";
  width: 10px;
  height: 2px;
  background: #fff;
  position: absolute;
  transition: all 0.2s ease-in-out 0s;
}
.login-form .group label .icon:before {
  left: 3px;
  width: 5px;
  bottom: 6px;
  transform: scale(0) rotate(0);
}
.login-form .group label .icon:after {
  top: 6px;
  right: 0;
  transform: scale(0) rotate(0);
}
.login-form .group .check:checked + label {
  color: #fff;
}
.login-form .group .check:checked + label .icon {
  background: #1161ee;
}
.login-form .group .check:checked + label .icon:before {
  transform: scale(1) rotate(45deg);
}
.login-form .group .check:checked + label .icon:after {
  transform: scale(1) rotate(-45deg);
}
.login-html
  .sign-in:checked
  + .tab
  + .sign-up
  + .tab
  + .login-form
  .sign-in-htm {
  transform: rotate(0);
}
.login-html .sign-up:checked + .tab + .login-form .sign-up-htm {
  transform: rotate(0);
}

.hr {
  height: 2px;
  margin: 60px 0 50px 0;
  background: rgba(255, 255, 255, 0.2);
}
.foot-lnk {
  text-align: center;
}
</style>
