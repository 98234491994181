import axios from '@/axios'
const route = 'report/controle_insumo';
const ReportControleInsumos = {
  namespaced: true,

  state: () => ({

  }),

  getters: {


  },

  mutations: {


  },
  actions: {
    index(context, data) {
      return axios.post(route,data)
    },
    getEstoqueBaixas(context, data) {
      return axios.post(route + "/get_estoque_baixas",data)
    },
    getAllCosts(){
      return axios.get("helper/get_all_costs")
    },
    getLoadStages(){
        return axios.get("helper/get_stages_per_cost_current")
    },
     getCostCurrent(){
        return axios.get("helper/get_cost_current")
     }
  }
};

export default ReportControleInsumos;
