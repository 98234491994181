import axios from '@/axios'
const ReportComposition = {
  namespaced: true,

  state: () => ({
    costId: null,
    stage: null
  }),

  getters: {

    getStage: (state) => {
      return state.stage;
    },

    // getCostId: (state) => {
    //     return state.costId;
    // },

    // getName: (state) => {
    //     return state.costName;
    // },
  },

  mutations: {

    setStage: (state, stage) => {
      state.stage = stage;
    },

    // setCostName: (state, id) => {
    //    state.costName = id;
    // },
  },
  actions: {
    getStageCompositions(context, cost_id) {
      const route = 'report/composition/stage_compositions/'
      return axios.get(route + cost_id)
    },




  }
};

export default ReportComposition;
