import axios from "axios"

const route = '/physical_progress';

export default {
  namespaced: true,

  state: () => ({
    dialogNewComposition: false
  }),
  getters: {

  },
  mutations: {

  },
  actions: {
    index(context, data) {
      let pagination = `?page=${data.page}&items_per_page=${data.ItemsPerPage || 10}&search=${data.search}`
      return axios.get(`${route}/metering${pagination}`)
    },


    //contractor summary
    getContractSummary(context, contractor_id) {
      return axios.get(route + '/metering/contractor_summary/' + contractor_id)
    },

    updateCreateContractorSummary(context, data) {
      return axios.post(route + '/metering/update_create_contractor_summary/' + data.contractor_id, data)
    },

    approvedServices(context, contractor_id) {
      return axios.post(route + '/metering/approved_services/' + contractor_id)
    },

    //carrega os empreiteiros
    getContractors(){
      return axios.get('helper/get_all_contractor')
    },

    //historico das medicoes
    getHistoricMetering() {
      return axios.get(route + '/metering/historic_metering')
    },

    //exportar pdf
    getExportPdf(context, contractor) {
      return axios.get(route + '/metering/export_pdf/' + contractor.id, { responseType: 'blob' })
    },

    //carrega os serviços disponiveis para o empreiteiro
    getServiceContractor(context, contractor) {
      return axios.get(route + '/metering/get_service_available_per_contractor/' + contractor.id)
    },

    //verifica se o empreiteiro tem a quantidade disponivel
    getQuantityAvailableContractor(context, data){
        return axios.post(route + '/metering/get_quantity_available_contractor',data)
    },

    //carregar os custos
    getCost() {
      return axios.get('helper/get_all_costs');
    },
    // getStagesPerCost(context, costId) {
    //   return axios.get('helper/get_stages_per_cost/' + costId);
    // },

    getStagesPerCostCurrent(){
        return axios.get('helper/get_stages_per_cost_current');
    },

    /**
     * Deletar um contrato
     */
    destroy(context,meteringContract){
        return axios.delete(route+'/metering/'+meteringContract.id)
    }



  }
}


