<template>
  <v-navigation-drawer
    src="@/assets/background_menu.jpg"
    color="blue"
    v-model="drawer"
    app
    overlay-color="black"
    light
    width="270"
  >
    <!-- PROFILE -->
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title dense class="text-h6 white--text"
          >Chess Obra</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
    <v-divider color="#c8c8c8"></v-divider>
    <v-list-item>
      <v-avatar class="mr-2 mt-2 mb-2">
        <img v-if="getPhoto != null" :src="'data:image/png;base64,' + getPhoto" />
        <img v-else src="@/assets/default-avatar.png" />
      </v-avatar>
      <v-list-item-content>
        <v-list-item-title class="white--text">{{ getUser.name }}</v-list-item-title>
        <v-list-item-subtitle class="white--text">{{
          getUser.occupation
        }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <!-- END PROFILE -->

    <v-divider color="#c8c8c8"></v-divider>

    <v-list dense dark style="width: 270px">
      <div v-for="(menu, i) in menus" :key="i">
        <!-- se tem submenus  -->
        <div v-if="menu.items">
          <v-list-group color="#B2EBF2" :value="false" :prepend-icon="menu.icon">
            <template v-slot:activator>
              <v-list-item-title>{{ menu.menu_name }}</v-list-item-title>
            </template>

            <v-list-item v-for="(item, i) in menu.items" :key="i" :to="item.link">
              <v-icon small v-text="item.icon" class="ml-3 mr-3"></v-icon>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
        <!-- CASO NÃO TENHA SUBMENU -->
        <div v-else>
          <v-list-item :to="menu.link">
            <v-list-item-icon dark>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ menu.menu_name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </div>

      <!-- group -->
      <template v-if="super_admin">
        <v-list-group color="#B2EBF2" :value="false" prepend-icon="mdi-gavel">
          <template v-slot:activator>
            <v-list-item-title>Admin</v-list-item-title>
          </template>

          <v-list-item v-for="(item, i) in admins" :key="i" :to="item.link">
            <v-list-item-title v-text="item.title"></v-list-item-title>
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </template>

      <!-- <v-list-group
                color="#B2EBF2"
                :value="false"
                prepend-icon="mdi-alert"
            >
                <template v-slot:activator>
                    <v-list-item-title>Desenvolvedor</v-list-item-title>
                </template>

                <v-list-item
                    v-for="(item, i) in developer"
                    :key="i"
                    :to="item.link"
                >
                    <v-icon small v-text="item.icon" class="ml-3 mr-3"></v-icon>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item>
            </v-list-group> -->
      <!-- fim -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import menu from "../../../config/menu";
export default {
  props: ["drawer"],
  created() {
    this.menus = menu;
  },
  data() {
    return {
      admins: [
        {
          title: "Cadastro de Organização",
          icon: "mdi-account-multiple-outline",
          link: "/system/register_organization",
        },
      ],

      // system: [
      //     {
      //         title: "Perfis",
      //         icon: "mdi-checkbox-blank-circle-outline",
      //         link: "/system/system/role",
      //     },
      // ],
      developer: [
        {
          title: "Cadastrar Permissoes",
          icon: "mdi-checkbox-blank-circle-outline",
          link: "/system/developer/permission",
        },
      ],
      corporateName: [],
      right: null,
    };
  },
  computed: {
    ...mapGetters("Login", ["getUser", "getPhoto"]),

    super_admin() {
      if (this.getUser.user_type == "super_admin") {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
white {
  color: white;
}
</style>
>
